import React, { Component } from 'react';
import './HeaderComponent.css';
import {
    EP_ACCOUNT, ET_ACCOUNT_ONLOAD, HEADER_ACCOUNT_BUTTON,
    LOGIN_EVENT_PAGE, LOGIN_TOGGLE_ON_EVENT_TYPE, TOGGLE_SELF_CLICK,
    HEADER_SINGLE_SEARCH_SEARCHBAR_ICON, HEADER_SINGLE_SEARCH_SEARCHBAR_ENTER,
    ET_HEADER_SINGLE_SEARCH, HEADER_SINGLE_SEARCH_SEARCHBAR_SEARCH,
    HEADER_SINGLE_SEARCH_SEARCHBAR_OPTION, HEADER,
    HEADER_WHAT_WE_DO_BUTTON, HEADER_LEAD_GEN, EP_HOMEPAGE,
    SIGNUP_EVENT_PAGE, SIGNUP_TOGGLE_ON_EVENT_TYPE, 
    ET_VALUATION_REPORT_PPC_SAMPLE_REPORT_BTN_CLICK, 
    ET_SOLUTIONS_MENU_MODAL_CLICK,
    BUY_SELL_BUSINESS_PPC_HOME_GET_IN_TOUCH_BTN_CLICK

} from '../../common/trackingConstants';
import localStorageApi from '../../api/localStorageApi';
import MediaQuery from 'react-responsive';
import { toggleMenuModal } from '../../redux/actions/menuAction';
import { toggleLoginModal, toggleSignupModal } from '../../redux/actions/loginSignupAction';
import {
    callSingleSearchApiAction,
    setActivityTrackingData,
    setCompanySearchAppliedFilter, setCompanySearchParams,
    setDirectorSearchAppliedFilter, setDirectorSearchParams, setTryPowerSearch
} from '../../redux/actions/searchAction';
import { 
    sendActivityTracking, setHeaderSearchText, 
    setHeaderSingleSearchOptions
} from '../../redux/actions/commonAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import analyticsApi from '../../api/analyticsApi';
import { GA_HP_SIGNUP_NOW_BTN, isBrowser, PROSPER_USER_TYPE } from "../../common/config";
import { TOGGLE_LOGIN_MODAL_ON_SUCCESS } from '../../common/loginSignupSuccessConstants';
import SingleSearchBar from '../SingleSearchBar/SingleSearchBar';
import { SR_SIGNUP_BTN_CLICK_HEADER } from '../../common/subscriptionConfigConst';
import { getOnloadEventType } from '../common/PowerSearchFunctions';
import { getDefaultFiltersForCompany, getSearchQueryDataObj } from '../../common/SearchCommonFunctions';
import { toggleSubmitInvestmentInterestModal } from '../../redux/actions/companyAction';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const MODEL_HEADING = `Start boosting your sales with Pomanda's lead generation tool 
'Power Search'.`
const MODEL_SUB_HEADING = `Filter the entire UK market by hundreds of filters.  Discover companies, 
directors, shareholders, industries and more.`
class HeaderComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHeaderSticky: false,
            isModalOpen: false,
            modalInputId: "",
            modalPlaceholder: "",
            modalEventCreated: "",
            modalEventType: "",
            modalOptionEventCreated: "",
            bgClass: "",
            isSearchbarShow: props && props.isSingleSearchbarSticky ? props.isSingleSearchbarSticky : false,
            productPathname: "",
            productHref: "",
        }
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleHeaderScroll);
        window.addEventListener('resize', this.getHeaderBgClass);
        this.getHeaderBgClass();
        if(typeof window !== undefined){
            const newPathname = window.location.pathname === "/" ? "/product" : window.location.pathname;
            const newHref = `${window.location.origin}/product`;
            this.setState({
                productPathname: newPathname,
                productHref: newHref,
            });
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        prevProps.headerProps && this.props.headerProps &&
            (((prevProps.headerProps.backgroundDesktop !== this.props.headerProps.backgroundDesktop
                || prevProps.headerProps.backgroundTab !== this.props.headerProps.backgroundTab
                || prevProps.headerProps.backgroundMob !== this.props.headerProps.backgroundMob))
                || (prevProps.screen && prevProps.screen.width
                    && this.props.screen && this.props.screen.width
                    && prevProps.screen.width !== this.props.screen.width))
            && this.getHeaderBgClass()
    }




    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleHeaderScroll);
        window.removeEventListener('resize', this.getHeaderBgClass);
    }

    handleHeaderScroll = () => {
        if (window.pageYOffset > 25) {
            !this.state.isHeaderSticky && this.setState({ isHeaderSticky: true })
            !this.state.isSearchbarShow && this.setState({ isSearchbarShow: true })
        } else {
            this.state.isHeaderSticky && this.setState({ isHeaderSticky: false })
            this.state.isSearchbarShow && this.setState({ isSearchbarShow: false })
        }
    }

    goToLandingPage = () => {
        this.props.history.push(this.props.whiteLabelConfig.header.headerlandingurl);
    }

    goToHomePage = () => {
        this.props.history.push("/");
    }

    goToAccountPage = () => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_ACCOUNT_ONLOAD,
            attribute1: HEADER_ACCOUNT_BUTTON,
        });
        if(this.props.userInfo?.userType === PROSPER_USER_TYPE){
            sessionStorage.setItem("prosperUserStatus","true")
        } else {
            sessionStorage.setItem("prosperUserStatus","false")
        }
        this.props.history.push("/account/profile");
    }
    goToSolutionPage = () => {
        const {
            headerSingleSearchOptions = {}
        } = this.props;
        const {
            eventPage = EP_HOMEPAGE
        } = headerSingleSearchOptions;
        this.props.dispatchSendActivityTracking({
            eventPage,
            eventType: HEADER_WHAT_WE_DO_BUTTON,
            attribute1: HEADER,
        });
        this.props.history.push("/solutions");
    }
    goToPowerSearchPage = () => {
        const {
            headerSingleSearchOptions = {},
            singleSearchTypes = [],
            isEstimateOn = false,
            companyQueryData = {},
            companyAppliedFilterList = {}
        } = this.props;
        const {
            eventPage = EP_HOMEPAGE
        } = headerSingleSearchOptions;
        this.props.dispatchSendActivityTracking({
            eventPage,
            eventType: HEADER_LEAD_GEN,
            attribute1: HEADER,
        });

        let newCompanyQueryData = JSON.parse(JSON.stringify(companyQueryData));
        newCompanyQueryData.sortField === "score" && (newCompanyQueryData.sortField = "companyName")
        const anonymousUser = localStorageApi.getAnonymousUser();
        const token = localStorageApi.getUserAuthToken();
        if (!token && !anonymousUser) {
            this.props.dispatchSetTryPowerSearch(true,
                {
                    heading: MODEL_HEADING,
                    subHeading: MODEL_SUB_HEADING
                })
            localStorageApi.anonymousUser();
        }
        const allAppliedFilters = {}
        allAppliedFilters["company"] = { ...getDefaultFiltersForCompany(), ...companyAppliedFilterList }
        this.props.dispatchCompanySearchAppliedFilter(allAppliedFilters.company);
        newCompanyQueryData.searchText = "";
        this.props.dispatchSetCompanySearchParams(newCompanyQueryData)

        companyQueryData.sortField === "score" && (companyQueryData.sortField = "companyName");
        this.props.dispatchCallSingleSearchApi({
            searchText: "",
            from: 0,
            size: 25,
            company: getSearchQueryDataObj(companyQueryData, allAppliedFilters, "company"),
            isEstimateOn,
            searchTypes: singleSearchTypes,
            trackingObj: {}
        });
        this.props.history.push("/powersearch/company");
    }

    handleLoginClick = (loginStatusObj) => {
        let trackingInfo = {
            eventPage: LOGIN_EVENT_PAGE,
            eventType: LOGIN_TOGGLE_ON_EVENT_TYPE,
            attribute1: TOGGLE_SELF_CLICK
        }
        this.props.dispatchToggleLoginModal(loginStatusObj, trackingInfo, {
            toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS
        });
    }

    handleClickOnSignupNow = () => {
        const {
            openSignUpModalInPage = false
        } = this.props?.headerProps || {};
        analyticsApi.gtag_report_conversion(GA_HP_SIGNUP_NOW_BTN)
        const pathName = isBrowser && window.location.pathname;
        const isBusinessPlanPage = pathName && pathName.includes('/businessplan');
         if (isBusinessPlanPage || (pathName && pathName === '/product') || openSignUpModalInPage) {
            this.props.dispatchToggleSignupModal({
                status: true,
            }, {
                eventPage: SIGNUP_EVENT_PAGE,
                eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
            });
        }
        else {
            this.redirectToProductPage(SR_SIGNUP_BTN_CLICK_HEADER)
        }
    }

    redirectToProductPage = (serviceReq) => {
        const pathname = isBrowser && window.location.pathname
        const href = isBrowser && window.location.href
        const path = `/product?serviceReq=${serviceReq}&url=${pathname}&href=${href}`;
        this.props.history.push(path);
    }

    handleTracking = (eventType) => {
        const {
            headerSingleSearchOptions = {}
        } = this.props;
        const {
            eventPage = EP_HOMEPAGE
        } = headerSingleSearchOptions;
        this.props.dispatchSendActivityTracking({
            eventPage,
            eventType,
            attribute1: HEADER
        });
    }

    goToProductPage = () => {
        if (this.props.location.pathname !== "/product") {
            const pathname = (isBrowser && window.location.pathname === "/") ? "/product" : window.location.pathname
            const href = `${window.location.origin}/product`;
            const path = `/product?serviceReq=DEFAULT&url=${pathname}&href=${href}`
            this.props.history.push(path);
        }
    }

    getHeaderBgClass = () => {
        const {
            headerProps = {},
            screen = {}
        } = this.props;
        const {
            backgroundDesktop = "",
            backgroundTab = "",
            backgroundMob = "",
        } = headerProps;
        const screenWidth = screen.width ? screen?.width : isBrowser ? window.innerWidth : screen.deviceType;
        const background = screenWidth ?
            screenWidth < 576 || 'mobile' ? backgroundMob
                : screenWidth < 992 || 'tablet' ? backgroundTab
                    : backgroundDesktop : "";
        let headerBackgroundClass = "";
        switch (background) {
            case "blue":
                headerBackgroundClass = "header-navbar-bg-blue"
                break;
            case "transparent":
                headerBackgroundClass = "header-navbar-bg-transparent"
                break;
            case "transparentBlack":
                headerBackgroundClass = "header-navbar-bg-transparentBlack"
                break;
            default:
                headerBackgroundClass = "header-navbar-bg-blue"
        }
        headerBackgroundClass !== this.state.bgClass
            && this.setState({ bgClass: headerBackgroundClass });
        return headerBackgroundClass;
    }

    getBgOnScroll = (colorName) => {
        switch (colorName) {
            case "blue":
                return "header-navbar-bg-blue"
            case "black":
                return "header-navbar-bg-black"
            default:
                return "header-navbar-bg-blue"
        }
    }
    onViewSampleBtnClick= ()=>{
        const path = this.props.headerProps?.customHeaderBtnTextProps?.path
        const url = this.props.headerProps?.customHeaderBtnTextProps?.url
        const eventName = this.props.headerProps?.customHeaderBtnTextProps?.eventName || ET_VALUATION_REPORT_PPC_SAMPLE_REPORT_BTN_CLICK
        this.handleTracking(eventName)
        
        if(path){
            this.props.history.push(path)
        } else if(url){
            window.open(`${window.origin}${url}`,"_blank")
        } else {
            window.open(`${window.origin}/assets/Download/PomandaBromptonBicycle.pdf`,"_blank")
        }
    }

    handleSampleReportBtnClick = (trackingObj) => {
        const activityTrackingObj = {
            ...trackingObj,
            attribute1: HEADER,
            attribute2: "NAVBAR"
        }
        this.props.dispatchSendActivityTracking(activityTrackingObj)
    }

    handleToggleMenuBarModal = (toggleStatus) => {
        this.handleTracking(ET_SOLUTIONS_MENU_MODAL_CLICK);
        this.props.toggleMenuModal(toggleStatus)
    }

    imageBasedOnDevice = (isSearchbarShowRender,showAccMenu, desktopWebpLogo, desktopLogo) => {
        if(this.props.screen?.width < 991.98) return false;
        const renderConditionForDesktop = isSearchbarShowRender && this.props.screen?.width < (showAccMenu ? 1125 : 1310)
        if (renderConditionForDesktop)
            return {
                webp: "logo_pom.webp",
                png: "logo_pom.png",
                width: "40",
                cssClass: "mr-3"
            }
        else return {
            webp: desktopWebpLogo,
            png: desktopLogo,
            width: "180",
            cssClass: "pomanda-logo-desktop"
        }
    }

    handleGetInTouchClick = (trackingPage) => {
        this.props.dispatchSubmitInterestModal({
            toggleStatus: true,
            subject: "Get In Touch",
            subHeading: "",
            heading: "Get In Touch",
            userMessage: "What can we help with?",
            descriptionLabel: "Message",
            mailRecipient: "CONTACT_US_EMAIL_ADDRESS",
            eventPage: trackingPage,
            eventType: BUY_SELL_BUSINESS_PPC_HOME_GET_IN_TOUCH_BTN_CLICK
        });
        this.props.dispatchSendActivityTracking({
            eventPage : trackingPage,
            eventType : BUY_SELL_BUSINESS_PPC_HOME_GET_IN_TOUCH_BTN_CLICK
        });
    }

    render() {
        let userTrackerObj = isBrowser && localStorageApi.getUserData()
        let showAccMenu = this.props.accessTokenStatus;
        if (userTrackerObj) {
            if (userTrackerObj.accessToken && userTrackerObj.accessToken !== "") {
                showAccMenu = true;
            }
        }
        const {
            headerSearchText = "",
            headerSingleSearchOptions = {},
            headerProps = {},
            whiteLabelConfig = {},
            businessPlanTemplateMenuData = {},
        } = this.props;
        let path = this.props.location.pathname;
        let pagePaths = path.split("/");
        if (pagePaths.includes("ppc")) {
            pagePaths.pop();
        }
        pagePaths.splice(0, 1);
        const ppcReportPageNames = [{
            name : "Search Companies",
            pathname : "companies",
            resemblePathName: "selectpackage"
        }, {
            name: "Make Payment",
            pathname: "payment"
        }, {
            name: "Valuation Calculator",
            pathname: "valuationcalculator"
        }];

        const {
            show = true,
            animation = true,
            backgroundOnScroll = "",
            desktopLogo = "",
            desktopWebpLogo = "",
            isPricingMenuShow = true,
            isSingleSearchbarSticky = true,
            showSingleSearchbar = true,
            customizedLogoType = null,
            showPowerSearchNav = true,
            showSolutionsNav = true,
            showAccountLoginNav = true,
            showSignupNav = true,
            showCompanyValuationNav = false,
            showMenuToggleTabMob = true,
            showBusinessPlanHeading = false,
            trackingPage="",
            showViewSampleReportBtn = true,
            showGetInTouchBtn = false,
            customHeaderBtnTextProps={},
            sameImageForAllDevices = false,
            headerCmpCssStr = ""
        } = headerProps;
        const {
            eventPage = EP_HOMEPAGE
        } = headerSingleSearchOptions
        let {
            powerSearchType = "company",
            screen = {}
        } = this.props;
        const isPricingMenuShowNew = whiteLabelConfig?.header ? false : isPricingMenuShow
        powerSearchType = powerSearchType === "" ? "company" : powerSearchType;
        const eventType = getOnloadEventType(powerSearchType);
        const isProductActive = this.props.location.pathname === "/product" ? true : false;
        const bgColorOnScroll = this.state.isHeaderSticky ? this.getBgOnScroll(backgroundOnScroll) : "";
        const initialBgColor = !this.state.isHeaderSticky && !this.state.bgClass && this.getHeaderBgClass();
        const isSearchbarShowRender = showSingleSearchbar && ((!isSingleSearchbarSticky && this.state.isSearchbarShow) || isSingleSearchbarSticky)
        const userType = this.props.userInfo?.userType;
        const {
            overview = {},
        } = businessPlanTemplateMenuData;
        const isValuationPath = this.props.location.pathname.includes("companyvaluation/companies")
        const deskTopimgObj = this.imageBasedOnDevice(isSearchbarShowRender,showAccMenu,desktopWebpLogo, desktopLogo);
        const isMobile = screen?.width <= 575.98 || screen?.deviceType === "mobile";
        const isTabletOrMobile = screen?.width <= 991.98 || screen?.deviceType === "tablet" || screen?.deviceType === "mobile";
        const nonStickyHeaderBgColor = !this.state.isHeaderSticky ? this.state.bgClass || initialBgColor : ""
        const productSelected = "PomandaPlus";
        
        return (
            <React.Fragment>
                {show ?
                    <div id="header-navbar"
                        className={`header-com-navbar ${nonStickyHeaderBgColor} ${bgColorOnScroll} ${this.state.isHeaderSticky && animation ? "header-com-navbar-anim" : ""} ${whiteLabelConfig?.header ? "header-navbar-bg-black" : ""} ${headerCmpCssStr}`}
                    >
                        <div className='d-flex align-items-center h-100'>
                            {customizedLogoType  ?
                                <div className='header-com-logo customize-logo'>
                                    <MediaQuery minWidth={sameImageForAllDevices ? 0 : 576 }>
                                    {whiteLabelConfig?.header && whiteLabelConfig?.header.desktopimagepath ? <picture>
                                                <source srcSet={whiteLabelConfig.header.desktopimagepath.sourceset} />
                                                <img pomande2eimglocation="header left logo" src={whiteLabelConfig.header.desktopimagepath.image} className="pomanda-logo-desktop" pomandae2eimgpath={whiteLabelConfig.header.desktopimagepath.image} pomandae2e={userType} width="180" height="70"
                                                    alt="pomanda" onClick={this.goToLandingPage} />
                                            </picture>:
                                        <picture>
                                            <source srcSet={`/assets/webpImages/${desktopWebpLogo}`} />
                                            <img src={`/assets/images/${desktopLogo}`} pomande2eimglocation="header left logo " pomandae2eimgpath={desktopWebpLogo} pomandae2e="pomandaLogo" className="pomanda-logo-desktop" pomandae2eForProsper={userType} width="180" height="40"
                                                alt="pomanda" onClick={this.goToHomePage} />
                                        </picture>}
                                    </MediaQuery>
                                    {!sameImageForAllDevices &&
                                    <MediaQuery maxWidth={575.98}>
                                        <picture>
                                            <source srcSet="/assets/webpImages/logo_pom.webp" />
                                            <img pomande2eimglocation="header left logo" src="/assets/images/logo_pom.png" className="pomanda-logo-tab" width="30" height="30"
                                                alt="m_pomanda" onClick={this.goToHomePage} />
                                        </picture>
                                    </MediaQuery>}
                                </div> :
                                <div className="header-com-logo" pomandae2eimgpath={desktopLogo} pomandae2e="pomandalogo">
                                    <MediaQuery minWidth={992}>
                                        <>
                                            {whiteLabelConfig?.header && whiteLabelConfig?.header.desktopimagepath ? <picture>
                                                <source srcSet={whiteLabelConfig.header.desktopimagepath.sourceset} />
                                                <img pomande2eimglocation="header left logo" src={whiteLabelConfig.header.desktopimagepath.image} className="pomanda-logo-desktop" pomandae2eimgpath={whiteLabelConfig.header.desktopimagepath.image} pomandae2e={userType} width="180" height="70"
                                                    alt="pomanda" onClick={this.goToLandingPage} />
                                            </picture> :
                                                <picture>
                                                <source srcSet={`/assets/webpImages/${deskTopimgObj.webp}`} />
                                                <img pomande2eimglocation="header left logo" pomandae2eimgpath={`/assets/images/${deskTopimgObj.png}`} src={`/assets/images/${deskTopimgObj.png}`} className={deskTopimgObj.cssClass} pomandae2eprosper="prosperlogo" pomandae2e={userType} width={deskTopimgObj.width} height="40" alt="pomanda" onClick={this.goToHomePage} />
                                            </picture>}
                                        </>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={991.98}>
                                        <>
                                            {whiteLabelConfig?.header && whiteLabelConfig?.header.mobileimagepath ? <picture>
                                                <source srcSet={whiteLabelConfig.header.mobileimagepath.sourceset} />
                                                <img src={whiteLabelConfig.header.mobileimagepath.image} className="pomanda-logo-tab" width="30" height="30"
                                                    alt="m_pomanda" onClick={this.goToLandingPage} />
                                            </picture> :
                                                <picture>
                                                    <source srcSet="/assets/webpImages/logo_pom.webp" />
                                                    <img src="/assets/images/logo_pom.png" className="pomanda-logo-tab" width="30" height="30"
                                                        pomandae2e={userType} alt="m_pomanda" onClick={this.goToHomePage} />
                                                </picture>}
                                        </>
                                    </MediaQuery>
                                </div>
                            }
                            {showBusinessPlanHeading && <div className='buss-temp-bld-right-heading white-color-text h-100 d-flex'>
                                <p className='m-auto'><b>Business Plan </b><br className='d-block d-sm-none' />
                                    <span className='d-none d-sm-inline'> - </span>
                                    {overview?.children?.description?.data?.companyName || ""}</p>
                            </div>}

                        </div>
                        {isSearchbarShowRender &&
                            <div className={`header-searchbar-section ${!isPricingMenuShowNew ? 'mr-auto' : ''}`}>
                                <SingleSearchBar
                                    formId="single_search_header_section"
                                    searchButtonPlaceholder="Search for a Company or Director..."
                                    searchTextboxValue={headerSearchText}
                                    inputId="header_single_search_input"
                                    eventCreatedKeyDown={HEADER_SINGLE_SEARCH_SEARCHBAR_ENTER}
                                    eventTypeKeyDown={eventType}
                                    eventPage={eventPage}
                                    eventTypeSearch={ET_HEADER_SINGLE_SEARCH}
                                    eventCreatedSearch={HEADER_SINGLE_SEARCH_SEARCHBAR_SEARCH}
                                    eventCreatedOptionClick={HEADER_SINGLE_SEARCH_SEARCHBAR_OPTION}
                                    eventCreatedIconClick={HEADER_SINGLE_SEARCH_SEARCHBAR_ICON}
                                    type="headerSearchbar"
                                    singleSearchTypes={[]}
                                />
                            </div>
                        }
                        <MediaQuery minWidth={992}>
                            <React.Fragment>
                                {showPowerSearchNav && <Link to="/powersearch/company" className={`header-com-menu header-nav-yellow`} 
                                     onClick={this.goToPowerSearchPage} pomandae2e="Power Search">POWER SEARCH
                                </Link>}
                                {isPricingMenuShowNew && <Link to={`/product?service=DEFAULT&productSelected=${productSelected}&url=${this.state.productPathname}&href=${this.state.productHref}`} 
                                         className={`${isProductActive ? "active-header-nav" : ""} header-com-menu header-nav-yellow`}
                                        onClick={this.goToProductPage}
                                        pomandae2e="Products" id="products_testing">PRICING
                                </Link>}
                                {showSolutionsNav && <div className={`header-com-menu ${!isPricingMenuShowNew ? 'ml-3' : ''}`}>
                                    <SolutionsDropdown handleTracking={() => this.goToSolutionPage} />
                                </div>}
                                {showAccountLoginNav ? showAccMenu ?
                                    <div className="header-com-menu header-nav-yellow">
                                        <span onClick={this.goToAccountPage} pomandae2e="accountbutton">ACCOUNT</span>
                                    </div> :
                                    <div className="header-com-menu header-nav-yellow">
                                        <span onClick={() => this.handleLoginClick({ status: true })} pomandae2e="login">LOG IN</span>
                                    </div> : null
                                }
                                {showSignupNav && !showAccMenu ?
                                    <div className="header-com-menu header-com-yellow-button header-com-menu-signup"
                                        onClick={this.handleClickOnSignupNow}
                                        pomandae2e="sign up"
                                    >SIGN UP
                                    </div> : null
                                }
                            </React.Fragment>
                        </MediaQuery>

                        {showCompanyValuationNav &&
                            <div className='customised-navbar-header'>
                            {( pagePaths[1] === "companyvaluation"&& isValuationPath ) ?
                                ppcReportPageNames.map((pageDetails, index) => {
                                    const checkCompletedStepCss = index === 0 ? "check-page-selected" : "";
                                    return ( <React.Fragment key={`${pageDetails.name}_${index}`}>
                                        <MediaQuery minWidth={992}>
                                            <div className={`page-check-navbar-container`}>
                                                <div className={`verify-page-checkbox ${checkCompletedStepCss}`}>
                                                    <i className="fas fa-solid fa-check"></i>
                                                </div>
                                                <div className='verify-page-name'>
                                                    <span className='stepping-process-number'>{index + 1}:</span>
                                                    <p className='stepping-process-name'> {pageDetails.name}</p>
                                                </div>
                                            </div> 
                                        </MediaQuery>
                                        <MediaQuery maxWidth={991.98}>
                                            {(pagePaths[pagePaths.length - 1] === pageDetails.pathname || pagePaths[pagePaths.length - 1] === pageDetails?.resemblePathName)  && <div className={`page-check-navbar-container`}>
                                                <div className='verify-page-name'>
                                                    <span className='stepping-process-number'>{index + 1}:</span>
                                                    <p className='stepping-process-name'>{pageDetails.name}</p>
                                                </div>
                                                <div className='verify-page-checkbox-container'>
                                                    {ppcReportPageNames.map((pageDetails, pageNumber) => 
                                                        <div className={`verify-page-checkbox ${pageNumber === 0 ? 'check-page-selected' : ""}`} key={`${pageDetails.name}_mobile_tablet_${pageNumber}`}>
                                                            <i className="fas fa-solid fa-check checkbox-navbar-icon"></i>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>} 
                                        </MediaQuery>
                                    </React.Fragment>)}
                                )
                            : null}
                                {showViewSampleReportBtn ?
                                    <div className={`header-com-menu header-com-yellow-button header-com-menu-signup ml-auto ${screen?.deviceType === "mobile" <= 991.98 && customizedLogoType !== "businessPlanPpc" ? 'ppc-val-header-btn' : "px-3"}`}
                                        onClick={this.onViewSampleBtnClick} 
                                        pomandae2e={isMobile ? customHeaderBtnTextProps.mobile || 'Sample Report' :
                                        customHeaderBtnTextProps.deskTop|| 'View sample report'}>
                                        {isMobile ? customHeaderBtnTextProps.mobile || 'Sample Report' :
                                        customHeaderBtnTextProps.deskTop|| 'View sample report'}
                                    </div> : null}
                                {showGetInTouchBtn ?
                                    <div className={`header-com-menu header-com-yellow-button header-com-menu-signup ml-auto ${isTabletOrMobile ? 'ppc-val-header-btn' : "px-3"}`}
                                        onClick={()=>this.handleGetInTouchClick(trackingPage)}>
                                        Get In Touch
                                    </div> : null}
                             </div>
                        }
                        <MediaQuery maxWidth={991}>
                            {showMenuToggleTabMob && <i className="fas fa-bars header-com-nav-toggle" pomandae2e="side menu" onClick={() => this.handleToggleMenuBarModal(true)}></i>}
                        </MediaQuery>
                    </div> : null
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    powerSearchType: state.searchReducer.powerSearchType,
    headerSearchText: state.common.headerSearchText,
    searchCompanyList: state.searchReducer.searchCompanyList,
    directorList: state.searchReducer.searchDirectorList,
    searchDirectorCount: state.searchReducer.searchDirectorCount,
    searchCompanyCount: state.searchReducer.searchCompanyCount,
    headerSingleSearchOptions: state.common.headerSingleSearchOptions,
    companyAppliedFilterList: state.searchReducer.appliedFilterList,
    directorAppliedFilterList: state.searchReducer.directorAppliedFilterList,
    userInfo: state.userProfile.userInfo,
    headerProps: state.common.headerProps,
    screen: state.common.screen,
    whiteLabelConfig: state.common.whiteLabelConfig,
    companyQueryData: state.searchReducer.companyQueryData,
    businessPlanTemplateMenuData: state.businessPlanTemplate.businessPlanTemplateMenuData,
    accessTokenStatus: state.common.accessTokenStatus
});

const mapDispatchToProps = (dispatch) => ({
    toggleMenuModal: (toggleStatus) => dispatch(toggleMenuModal(toggleStatus)),
    dispatchToggleLoginModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleLoginModal(toggleStatus, trackingInfo, onSuccessRender)),
    setActivityTrackingData: trackingObj => dispatch(setActivityTrackingData(trackingObj)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchSetHeaderSearchText: (searchText) => dispatch(setHeaderSearchText(searchText)),
    dispatchSetCompanySearchParams: (searchObj) => dispatch(setCompanySearchParams(searchObj)),
    dispatchSetDirectorSearchParams: (searchObj) => dispatch(setDirectorSearchParams(searchObj)),
    dispatchCompanySearchAppliedFilter: (appliedFilter) => dispatch(setCompanySearchAppliedFilter(appliedFilter)),
    dispatchDirectorSearchAppliedFilter: (appliedFilter) => dispatch(setDirectorSearchAppliedFilter(appliedFilter)),
    dispatchSetHeaderSingleSearchOptions: optionObj => dispatch(setHeaderSingleSearchOptions(optionObj)),
    dispatchSetTryPowerSearch: (status, tryPowerSearchData) => dispatch(setTryPowerSearch(status, tryPowerSearchData)),
    dispatchToggleSignupModal: (toggleStatus, trackingInfo) => dispatch(toggleSignupModal(toggleStatus, trackingInfo)),
    dispatchCallSingleSearchApi: (searchObj) => dispatch(callSingleSearchApiAction(searchObj)),
    dispatchSubmitInterestModal: (submitInterestModalObj) => dispatch(toggleSubmitInvestmentInterestModal(submitInterestModalObj))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent));

// const solutionsMenuItems = [{
//     heading: "find companies & directors",
//     subHeading: "Access billions of data points on UK companies and directors",
//     link: "/solutions/companyinformation",
//     eventType: ET_SOLUTIONS_FIND_COMPANY_DIRECTOR_MENU_CLICK
// }, {
//     heading: "Instant credit reports",
//     subHeading: "Check out customers and suppliers or see how your own business is rated",
//     link: "/solutions/companycreditcheck",
//     eventType: ET_SOLUTIONS_CREDIT_REPORT_MENU_CLICK
// }, {
//     heading: "value a business",
//     subHeading: "See what a business is worth - including small & private businesses",
//     link: "/solutions/companyvaluation",
//     eventType: ET_SOLUTIONS_VALUE_BUSINESS_MENU_CLICK
// }, {
//     heading: "lead generation",
//     subHeading: "Improve your sales by identifying & qualifying key prospects",
//     eventType: ET_SOLUTIONS_LEAD_GENERATION_MENU_CLICK,
//     link: "/solutions/leadgeneration"
// }, {
//     heading: "access funding",
//     subHeading: "Get help securing loans, new investment or selling your business",
//     eventType: ET_SOLUTIONS_ACCESS_FUNDING_MENU_CLICK,
//     link: "/solutions/investment"
// }, {
//     heading: `Company Health Check <span class="new-solution-menu">BETA</span>`,
//     subHeading: "Identify a business’s strengths and weaknesses",
//     eventType: ET_SOLUTIONS_SCORECARD_MENU_CLICK,
//     link: "/solutions/scorecard"
// },
// {
//     heading: `Financial Estimates`,
//     subHeading: "Help fill in the blanks left by Companies House with Pomanda’s estimates",
//     eventType: ET_SOLUTIONS_ESTIMATE_MENU_CLICK,
//     link: "/solutions/estimates"
// },
// {
//     heading: `Industry Insights`,
//     subHeading: "Research and statistics on all the major industries in the UK",
//     eventType: ET_SOLUTIONS_INDUSTRY_MENU_CLICK,
//     link: "/solutions/industry"
// }

// ]
const SolutionsDropdown = React.memo(function SolutionsDropdown(props) {
    // const [dropdownMenuHeight, setDropdownMenuHeight] = useState("auto");

    // const setHeightOfDropdownMenu = () => {
    //     const dropdownMenuHeight = isBrowser
    //         ? ((window.innerHeight - document.getElementById('header-navbar').offsetHeight) < document.getElementById("solutions_menu_dropdown").childNodes[1].offsetHeight
    //             ? "400px" : "auto") : "auto";
    //     setDropdownMenuHeight(dropdownMenuHeight);
    // }
    return (
        <Link to="/solutions" className="header-solutions-btn header-com-menu header-nav-yellow" id="solutions_menu_dropdown"
        // onMouseEnter={setHeightOfDropdownMenu}
        // onMouseLeave={setHeightOfDropdownMenu}
        
                pomandae2e="SOLUTIONS"
                onClick={props.handleTracking()}
                aria-haspopup="true"
                aria-expanded="false">
                SOLUTIONS
            {/* <div className="dropdown-menu" aria-labelledby="solutions_menu_dropdown_btn" style={{ height: dropdownMenuHeight }}>
    const [dropdownMenuHeight, setDropdownMenuHeight] = useState("auto");
    const [dropdownMenuHover, setDropdownMenuHover] = useState(false);

    const setHeightOfDropdownMenu = (flag) => {
        const deviceType = getDeviceType();
        const isDesktop = deviceType === "desktop";
        isDesktop && setDropdownMenuHover(flag);
        const solutionDropdownHeight = document.getElementById("solutions_menu_dropdown").childNodes[1].offsetHeight;
        const dropdownMenuHeightNew =  isBrowser 
        ? ((window.innerHeight - document.getElementById('header-navbar').offsetHeight) < (solutionDropdownHeight === 0 ? 597 : solutionDropdownHeight) 
        ? "400px": "auto") : "auto";
        setDropdownMenuHeight(dropdownMenuHeightNew);
    }

    const getDeviceType = () => {
        const ua = window.navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
          return "tablet";
        }
        if (
          /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
        ) {
          return "mobile";
        }
        return "desktop";
    };

    return (
        <div className={`dropdown ${dropdownMenuHover ? "d-block" : ""}`} id="solutions_menu_dropdown" 
            onMouseEnter={()=>setHeightOfDropdownMenu(true)}
            onMouseLeave={()=>setHeightOfDropdownMenu(false)}>
            <button className="header-solutions-btn header-com-menu header-nav-yellow dropdown-toggle"
                type="button"
                id="solutions_menu_dropdown_btn"
                pomandae2e="SOLUTIONS"
                data-toggle="dropdown"
                aria-haspopup="true">
                WHAT WE DO
            </button>
            <div className={`dropdown-menu ${dropdownMenuHover ? "d-block" : ""}`} aria-labelledby="solutions_menu_dropdown_btn" style={{height:dropdownMenuHeight}}>
                {
                    solutionsMenuItems.map((obj, index) =>
                        <SolutionsDropdownMenuItem
                            key={`header_solutions_menu_item_${index}`}
                            heading={obj.heading}
                            subHeading={obj.subHeading}
                            link={obj.link}
                            cssClass={index !== 0 ? "menu-item-margin-top" : ""}
                            eventType={obj.eventType}
                            handleTracking={props.handleTracking} />
                    )
                }
            </div> */}
        </Link>
    )
});

// const SolutionsDropdownMenuItem = (props => {
//     const {
//         cssClass = "",
//         heading = "",
//         subHeading = "",
//         link = "",
//         eventType = ""
//     } = props;
//     return (
//         <Link to={link} onClick={() => props.handleTracking(eventType)}>
//             <div className={cssClass && cssClass !== "" ? props.cssClass : ""}>
//                 <p className="menu-item-header text-capitalize" pomandae2e={heading}
//                     dangerouslySetInnerHTML={{ __html: heading }} />
//                 <p className="menu-item-sub-header font-weight-normal" pomandae2e={subHeading}>
//                     {subHeading}
//                 </p>
//             </div>
//         </Link>
//     )
// })
